import { Box, IconButton } from '@mui/material';
import { SButton } from '@setvi/shared/components';
import SFormikTextfield from '@setvi/shared/components/sforms/sformik/formik-text-field';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import LockIcon from '@mui/icons-material/Lock';
import { useState } from 'react';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';

interface OTPCodeFormProps {
  initialValues: {
    OtpCode: string;
  };
  isLoading: boolean;
  onSubmit: (values: { OtpCode: string }) => void;
}

const validationSchema = Yup.object({
  OtpCode: Yup.string().trim().required('Passcode is required')
});

export const OTPCodeForm = ({
  initialValues,
  isLoading,
  onSubmit
}: OTPCodeFormProps) => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}>
      <Form>
        <Box mt={5}>
          <SFormikTextfield
            startIcon={<LockIcon htmlColor="#696974" />}
            endIcon={
              viewPassword ? (
                <IconButton onClick={() => setViewPassword(prev => !prev)}>
                  <VisibilityOffTwoToneIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setViewPassword(prev => !prev)}>
                  <VisibilityTwoToneIcon />
                </IconButton>
              )
            }
            name="OtpCode"
            type={viewPassword ? 'text' : 'password'}
            placeholder="Enter Passcode"
          />
        </Box>
        <Box mt="30px">
          <SButton
            loading={isLoading}
            fullWidth
            style={{
              backgroundColor: '#27BDBE',
              textTransform: 'capitalize',
              height: 57
            }}
            type="submit">
            Login
          </SButton>
        </Box>
      </Form>
    </Formik>
  );
};
