export interface Account {
  CovetrusID: string;
  AccountName: string;
  EnrollmentLevel: string;
  TypeOfCustomer: string;
  EnrollmentStartDate: string;
  EnrollmentEndDate: string;
  FTE: number;
  PrimaryPractice: string;
  PracticeEmail: string;
  FullyConfigured: string;
  PIMS: string;
  Communications: string;
  Payments: string;
  vRxPro: string;
  DistributionPerformanceQTD: number;
  DistributionPerformanceYTD: number;
  CompoudingPerformanceQTD: number;
  CompoudingPerformanceYTD: number;
  DistributionCommitmentPercent: number;
  CompoudingCommitmentPercent: number;
  DistributionCommitment: number;
  CompoudingCommitment: number;
  StaticRebate: number;
  PerDVMRebate: number;
  RebateAmount: number;
  AccountManagerName: string;
  AccountManagerEmail: string;
  AccountManagerPhone: string;
  Requirements: AccountRequirements;
  Benefits: AccountBenefits;
}

export enum EnrollmentLevel {
  Core = 'Core',
  Select = 'Select',
  Premier = 'Premier'
}

export interface AccountRequirements {
  DistributionCommitment: number;
  DistributionPreferredPartner: string;
  ZoetisDiagnosticCommitment: boolean;
  CompoundingCommitment: number;
  FullyConfiguredVxPro: boolean;
  Communications: boolean;
  Payments: boolean;
  PIMS: string;
}

export interface AccountBenefits {
  Type: string;
  StaticRebate: number;
  PerDVMRebate: number;
  DedicatedVetCareSupportTeam: boolean;
  QuartelyBusinessReviews: boolean;
  PersonalizedECommerceMarketing: boolean;
  InventoryManagementEducation: boolean;
  ExclusiveOSHAEducation: boolean;
  OneOnOneTrainingAndOnboarding: boolean;
  HypercareTechnologySupport: boolean;
  PriorityAccessToInnovationAndAllocationAndBackorderedProducts: boolean;
  WhiteGloveService: boolean;
  AfterHoursTechSupport: boolean;
  PracticePerformanceReporting: boolean;
  PracticeBenchmarkReporting: boolean;
  WaiveShippingFees: boolean;
}
