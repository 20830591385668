import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { SText, SInfiniteTable, TableLoader } from '@setvi/shared/components';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer
} from 'recharts';
import { VetsuiteOverview } from '@setvi/shared/services';
import TableBodyCells from '@setvi/shared/components/s-table/components/table/cells';
import { useVetSuiteDigitalShowroomAnalytics } from '../../../../hooks';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';
import { useDigitalShowroomContext } from '../../../../../../providers/app-provider/context';
import { Requirement } from './requirement';
import { Section } from '../../../../../../components/section';
import { usePurchasingTable } from './usePurchasingTable';
import { PerformanceActivityLegend } from './performance-activity-legend';
import { usePerformanceActivity } from './usePerformanceActivity';
import { useVetsuiteTransactions } from './useVetsuiteTransactions';

interface PerformanceActivityLegendProps {
  overview: VetsuiteOverview;
}

export const PerfomranceActivity = ({
  overview
}: PerformanceActivityLegendProps) => {
  const { columns } = usePurchasingTable();
  const { isLoading, weeklyPerformance } = usePerformanceActivity({ overview });
  const { customerId } = useVetsuiteDigitalShowroomContext();
  const { loggedInUserEmail } = useDigitalShowroomContext();
  const { captureAnalytics } = useVetSuiteDigitalShowroomAnalytics();
  const {
    isLoading: transactionsLoading,
    transactions,
    fetchTransactions
  } = useVetsuiteTransactions();

  const requirements = useMemo(
    () => [
      {
        iconBackgorundColor: '#F6F6F6',
        icon: '/icons/covetrus-logo-icon.svg',
        label: 'VetSuite Commitment Level',
        description: `${overview?.commitmentLevel}%`
      },
      {
        iconBackgorundColor: '#021660',
        icon: '/icons/truck-icon.svg',
        label: 'Distribution Performance',
        description:
          Math.round(
            (overview?.distributionYtd / overview?.distributionCommitment) * 100
          ) < overview?.commitmentLevel
            ? `${Math.round(
                (overview?.distributionYtd / overview?.distributionCommitment) *
                  100
              )}%`
            : 'Commitment Met'
      },
      {
        iconBackgorundColor: '#27BDBE',
        icon: '/icons/bowl-icon.svg',
        label: 'Compounding Performance',
        description:
          Math.round(
            (overview?.compoundingYtd / overview?.compoundingCommitment) * 100
          ) < overview?.commitmentLevel
            ? `${Math.round(
                (overview?.compoundingYtd / overview?.compoundingCommitment) *
                  100
              )}%`
            : 'Commitment Met'
      }
    ],
    [overview]
  );

  useEffect(() => {
    if (loggedInUserEmail)
      captureAnalytics({
        actionType: 'PerformanceActivityTab',
        covetrusKey: customerId,
        timestamp: new Date(),
        userEmail: loggedInUserEmail
      });
  }, [captureAnalytics, customerId, loggedInUserEmail]);

  return (
    <Section>
      <Box
        display="flex"
        alignItems="center"
        gap={4}
        sx={theme => ({
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: '12px'
          }
        })}>
        {requirements.map(requirement => (
          <Requirement
            key={requirement.label}
            label={requirement.label}
            description={requirement.description}
            iconBackgorundColor={requirement.iconBackgorundColor}
            iconSrc={requirement.icon}
          />
        ))}
      </Box>
      <Box
        mb={4}
        mt={1}
        display="flex"
        justifyContent="end"
        sx={theme => ({ color: theme.palette.grey[600] })}>
        <SText
          style={{ color: '#44444F' }}
          title={`Updated as of ${moment(overview?.updatedAt).format('l')}`}
          size="xs"
        />
      </Box>
      <Box
        p={4}
        sx={theme => ({
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '9px'
        })}>
        <SText
          noLineClamp
          title="Your Weekly Performance Over Time"
          weight="bold"
        />
        {isLoading ? (
          <Box
            height={200}
            display="flex"
            alignItems="center"
            justifyContent="center">
            LOADING...
          </Box>
        ) : (
          <Box mt={3} ml={-6}>
            <ResponsiveContainer width="100%" height={250}>
              <LineChart
                width={730}
                height={250}
                data={weeklyPerformance}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" strokeDasharray="3 3" />
                <YAxis strokeDasharray="3 3" />
                <Tooltip
                  formatter={(value, name) => [
                    `${Number(value).toFixed(2).replace(/\.00$/, '')}%`,
                    name
                  ]}
                />
                <Line
                  type="monotone"
                  dataKey="commitment"
                  stroke="#ED3030"
                  strokeWidth={3}
                />
                <Line
                  type="monotone"
                  dataKey="distribution"
                  stroke="#021660"
                  strokeWidth={3}
                />
                <Line
                  type="monotone"
                  dataKey="compound"
                  stroke="#27BDBE"
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        )}
        <Box
          mt="22px"
          display="flex"
          flexWrap="wrap"
          gap={2}
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              marginTop: '16px'
            }
          })}>
          <PerformanceActivityLegend title="Commitment Level" color="#ED3030" />
          <Box>
            <PerformanceActivityLegend
              title="Distribution Performance"
              color="#021660"
            />
          </Box>
          <Box>
            <PerformanceActivityLegend
              title="Compounding Performance"
              color="#27BDBE"
            />
          </Box>
        </Box>
      </Box>
      <Box
        my={5}
        sx={theme => ({
          [theme.breakpoints.down('sm')]: {
            margin: '24px 0 12px 0'
          }
        })}>
        <SText title="Purchasing" weight="semibold" />
      </Box>

      <SInfiniteTable
        header={{ columns }}
        infiniteScroll={{
          list: transactions,
          // eslint-disable-next-line no-underscore-dangle
          keyGetter: row => row._id,
          fetchMore: fetchTransactions,
          render: row => (
            <TableBodyCells
              // temporary solution remove when mui is updated to v5
              style={{ padding: '8px' }}
              columns={columns}
              rowData={row}
              // eslint-disable-next-line no-underscore-dangle
              rowKeyGetter={r => r._id}
            />
          )
        }}
      />
      {transactionsLoading && <TableLoader columns={columns} />}

      {!transactionsLoading && !transactions?.length && (
        <Box textAlign="center" mt={4}>
          No Transactions Found.
        </Box>
      )}
    </Section>
  );
};
