import { SRouteGuard } from '@setvi/shared/components';
import { useDigitalShowroomContext } from 'providers/app-provider/context';
import { ROUTES } from 'router/routes';
import { StringParam, useQueryParam } from 'use-query-params';

interface ProtectedVetsuiteShowroomProps {
  children: React.ReactNode;
}

export const ProtectedVetsuiteShowroom = ({
  children
}: ProtectedVetsuiteShowroomProps) => {
  const { token } = useDigitalShowroomContext();
  const [customerId] = useQueryParam('customerId', StringParam);

  const vetsuiteModuleEnabled = Boolean(token) && Boolean(customerId);

  return (
    <SRouteGuard
      routeEnabled={vetsuiteModuleEnabled}
      redirectRoute={ROUTES.LOGIN}>
      {children}
    </SRouteGuard>
  );
};
