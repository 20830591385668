import { Box, Divider, Link } from '@mui/material';
import { SText } from '@setvi/shared/components';
import {
  Milestone,
  MilestoneProps
} from '../../../../../../../components/milestone';
import { CurrentLevelPill } from '../current-level-pill';
import { ProgramValue } from '../program-value';
import {
  PerformanceRequirement,
  PerformanceRequirementProps
} from '../performance-requirement';

export interface ProgramProps {
  programName: string;
  isProgramSelected?: boolean;
  performanceRequirements: PerformanceRequirementProps[];
  techRequirements: MilestoneProps[];
  prefferedPartnerTitle?: string;
  prefferedPartner?: MilestoneProps[];
  zoetisDiagnosticCommitment?: MilestoneProps[];
  estimatedTotalValue: number;
  benefitsTitle: string;
  benefits: MilestoneProps[];
  useBulletPointsForBenefits: boolean;
  onContactAccountManager?: () => void;
}

export const Program = ({
  programName,
  isProgramSelected = false,
  performanceRequirements,
  techRequirements,
  prefferedPartnerTitle,
  prefferedPartner,
  zoetisDiagnosticCommitment,
  estimatedTotalValue,
  benefitsTitle,
  benefits,
  useBulletPointsForBenefits,
  onContactAccountManager
}: ProgramProps) => (
  <Box
    mt={2}
    p={4}
    position="relative"
    width="100%"
    maxWidth={480}
    sx={theme => ({
      border: `0.5px solid ${isProgramSelected ? `#27BDBE` : theme.palette.divider}`,
      borderRadius: '8px',
      backgroundColor: isProgramSelected
        ? '#27BDBE1A'
        : theme.palette.common.white
    })}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height={180}>
      <SText
        style={{ whiteSpace: 'pre-line' }}
        title={programName}
        noLineClamp
        size="4xl"
        weight="bold"
      />
      {isProgramSelected && (
        <Box
          position="absolute"
          top="-22px"
          left="50%"
          style={{ transform: 'translatex(-50%)' }}>
          <CurrentLevelPill />
        </Box>
      )}
      <Box textAlign="center">
        {!isProgramSelected && (
          <SText size="sm" noLineClamp>
            <b>For information only</b>,{' '}
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => onContactAccountManager?.()}>
              Please contact your Veterinary Relationship Manager
            </Link>{' '}
            for Enrollment Details
          </SText>
        )}
      </Box>
    </Box>
    <Box my={5}>
      <Divider />
    </Box>
    <Box my={4}>
      <SText title="Performance Requirements" size="lg" weight="bold" />
    </Box>
    <Box>
      {performanceRequirements.map((requirement, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box mb={3} key={index}>
          <PerformanceRequirement
            icon={requirement.icon}
            title={requirement.title}
            description={requirement.description}
            currentProgress={requirement.currentProgress}
            sliderProgressTitle={requirement.sliderProgressTitle}
            sliderProgress={requirement.sliderProgress}
          />
        </Box>
      ))}
    </Box>
    <Box my={5}>
      <Divider />
    </Box>
    <Box my={4}>
      <SText title="Tech Requirements" size="lg" weight="bold" />
    </Box>
    <Box>
      {techRequirements.map((requirement, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box mb={3} key={index}>
          <Milestone
            icon={requirement.icon}
            title={requirement.title}
            steps={requirement.steps}
          />
        </Box>
      ))}
    </Box>
    <Box my={5}>
      <Divider />
    </Box>
    {prefferedPartner && prefferedPartnerTitle && (
      <>
        {prefferedPartnerTitle && (
          <Box my={4}>
            <SText title={prefferedPartnerTitle} size="lg" weight="bold" />
          </Box>
        )}
        <Box>
          {prefferedPartner.map((requirement, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box mb={3} key={index}>
              <Milestone
                icon={requirement.icon}
                title={requirement.title}
                steps={requirement.steps}
              />
            </Box>
          ))}
        </Box>
        <Box my={5}>
          <Divider />
        </Box>
      </>
    )}
    {zoetisDiagnosticCommitment && (
      <>
        <Box my={4}>
          <SText title="Zoetis Diagnostic Commitment" size="lg" weight="bold" />
        </Box>
        <Box>
          {zoetisDiagnosticCommitment.map((requirement, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box mb={3} key={index}>
              <Milestone
                icon={requirement.icon}
                title={requirement.title}
                steps={requirement.steps}
              />
            </Box>
          ))}
        </Box>
        <Box my={5}>
          <Divider />
        </Box>
      </>
    )}
    <Box my={4}>
      <SText title={benefitsTitle} size="lg" weight="bold" />
    </Box>
    <Box>
      <Box mb={3}>
        <ProgramValue
          estimatedTotalValue={estimatedTotalValue}
          valueHighlighted={!isProgramSelected}
        />
      </Box>
      {benefits.map((requirement, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} mb={3}>
          <Milestone
            icon={requirement.icon}
            title={requirement.title}
            steps={requirement.steps}
            useBulletPoints={useBulletPointsForBenefits && !requirement.icon}
          />
        </Box>
      ))}
    </Box>
  </Box>
);
