import {
  VetsuiteOverview,
  PerformanceOverview,
  PurchaseType
} from '@setvi/shared/services';
import { useCallback, useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { enqueueSnackbar } from 'notistack';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';

interface usePerformanceActivity {
  overview: VetsuiteOverview;
}

export const usePerformanceActivity = ({
  overview
}: usePerformanceActivity) => {
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [weeklyPerformance, setWeeklyPerformance] = useState<
    PerformanceOverview[]
  >([]);
  const { currentUser } = useVetsuiteDigitalShowroomContext();
  const [customerId] = useQueryParam('customerId', StringParam);

  const fetchWeeklyPerformance = useCallback(async () => {
    setIsloading(true);
    try {
      const result = await currentUser.functions.vetsuiteWeeklyPerformance(
        customerId,
        overview?.vetsuiteEnrollmentStartDate,
        [PurchaseType.Compounding, PurchaseType.Distribution],
        overview?.distributionCommitment,
        overview?.compoundingCommitment,
        overview?.commitmentLevel
      );

      setWeeklyPerformance(result);
    } catch (e) {
      enqueueSnackbar('Error occurred while getting performance data.', {
        variant: 'error'
      });
    } finally {
      setIsloading(false);
    }
  }, [overview, currentUser, customerId]);

  useEffect(() => {
    fetchWeeklyPerformance();
  }, [fetchWeeklyPerformance]);

  return {
    weeklyPerformance,
    isLoading
  };
};
