import { Box, useMediaQuery, useTheme } from '@mui/material';
// import { PawCircleIcon } from '@setvi/shared/components/sicons';
import {
  RadialBar,
  RadialBarChart,
  //   Tooltip,
  //   Legend,
  //   Text,
  LabelList,
  // Label,
  //   Cell,
  ResponsiveContainer,
  PolarAngleAxis
} from 'recharts';

export enum RadialChartTypeColor {
  DistributionPerformance = '#021660',
  CompoundPerformance = '#27BDBE'
}

// const data = [
//   { uv: 35, fill: '#27BDBE' },
//   { uv: 20, fill: '#021660' }
// ];

// TODO: calculate correct position of the icons in the 180 deg radial chart

// const renderCustomBarLabel = (props: any) => {

//   const { cx, cy, endAngle, startAngle, innerRadius } = props.viewBox;

//   const middleRadius = 180;

//   const midAngle = (startAngle + endAngle) / 2;
//   const radius = (innerRadius + middleRadius) / 2;
//   const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
//   const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

//   return <PawCircleIcon style={{ fill: '#fff' }} x={x} y={y} />;
// };

interface RadialChartProps {
  goal: number;
  data: { progress: number; fill: string; label: string }[];
}

export const RadialChart = ({ goal, data }: RadialChartProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box height={250}>
      <ResponsiveContainer width="100%" height={400}>
        <RadialBarChart
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          innerRadius="70%"
          outerRadius="100%"
          barSize={20}
          data={data}
          startAngle={180}
          endAngle={0}>
          <PolarAngleAxis
            type="number"
            domain={[0, goal]}
            angleAxisId={0}
            tick={false}
          />
          <RadialBar background dataKey="progress">
            <LabelList dataKey="label" position="right" fill="#fff" />
          </RadialBar>
          <text
            x="50%"
            y="37%"
            width={30}
            textAnchor="middle"
            fontWeight={500}
            dominantBaseline="middle"
            fill="#696974">
            COMMITMENT LEVEL
          </text>
          <text
            x="50%"
            y="44%"
            width={30}
            textAnchor="middle"
            dominantBaseline="middle"
            fontWeight="bold"
            fontSize="25px"
            fill="#ED3030">
            {goal}%
          </text>
          {matches && (
            <>
              <text
                x="10%"
                y="47%"
                width={30}
                textAnchor="middle"
                dominantBaseline="middle"
                fontWeight="bold"
                fill="#696974">
                0%
              </text>
              <text
                x="90%"
                y="47%"
                width={30}
                textAnchor="middle"
                dominantBaseline="middle"
                fontWeight="bold"
                fill="#696974">
                {goal}%
              </text>
            </>
          )}

          {/* <LabelList dataKey="uv" /> */}

          {/* <Legend
  iconSize={10}
  width={120}
  height={140}
  layout="vertical"
  verticalAlign="middle"
  align="right"
/> */}
          {/* <Tooltip /> */}
        </RadialBarChart>
      </ResponsiveContainer>
    </Box>
  );
};
