import { Box } from '@mui/material';

interface CompanyContactProps {
  children: React.ReactNode;
}

export const CompanyContact = ({ children }: CompanyContactProps) => (
  <Box
    display="flex"
    alignItems="center"
    boxSizing="border-box"
    sx={theme => ({
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: '16px 14px',
      width: '100%',
      fontSize: '18px !important',
      [theme.breakpoints.down('md')]: {
        padding: '17px'
      }
    })}>
    {children}
  </Box>
);
