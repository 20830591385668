import { Box } from '@mui/material';
import { SETVIImage, SText } from '@setvi/shared/components';
import { MileStoneStepProps, MilestonStep } from './step';

export interface MilestoneProps {
  icon?: string;
  title?: string;
  useBulletPoints?: boolean;
  steps: MileStoneStepProps[];
}

export const Milestone = ({
  icon,
  title,
  steps,
  useBulletPoints
}: MilestoneProps) => (
  <Box
    display="flex"
    sx={theme => ({
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
      padding: '12px',
      backgroundColor: theme.palette.common.white
    })}>
    {icon && (
      <Box
        width={42}
        height={42}
        display="flex"
        alignItems="center"
        justifyContent="center"
        mr={2}
        sx={theme => ({
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '5px'
        })}>
        <SETVIImage src={icon} alt="icon" />
      </Box>
    )}
    <Box>
      <Box>
        <Box mb={1}>
          <SText size="xs" title={title} fontColor="#696974" />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gap: '10px'
        }}>
        {steps.map(step => (
          <MilestonStep
            key={step.description}
            completed={step.completed}
            description={step.description}
            useBulletPoints={useBulletPoints}
          />
        ))}
      </Box>
    </Box>
  </Box>
);
