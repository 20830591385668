import { AxiosMethods, DigitalShowroomApi } from '@setvi/shared/enums';
import { axiosHelper } from '@setvi/shared/services';

interface GetCovetrusContactProps {
  contactEmail: string;
  endpoint: string;
}

export const getCovetrusContact = () => ({
  mutationFn: ({ contactEmail, endpoint }: GetCovetrusContactProps) =>
    axiosHelper({
      endpoint,
      method: AxiosMethods.POST,
      headers: {
        apiKey:
          'gU0mRYzRfYAsA7KOgv3hnJv9npeHgDdqJr9cXBz5SbPgOK13L4fTesxZKPaPYTxV'
      },
      body: {
        contactEmail
      }
    })
});

interface StartPasswordlessLoginVetsuiteProps {
  email: string;
}

export const startPasswordlessLoginVetsuite = () => ({
  mutationFn: ({ email }: StartPasswordlessLoginVetsuiteProps) =>
    axiosHelper({
      endpoint: DigitalShowroomApi.VetusiteAuth0PasswordlessStart,
      method: AxiosMethods.POST,
      body: {
        client_id: 'DchfbzBsTA4RZzzDocrjBhmqL4HynrqJ',
        client_secret:
          'doBNXg1EXJZBQvBqiSC_F7yT0Yx4T1xp7QtxdrhXb6RhDxoaJ3y2VSq6T2X2Hm-6',
        connection: 'email',
        email,
        send: 'code',
        authParams: {
          scope: 'openid'
        }
      }
    })
});

interface GetVetsuiteTokenProps {
  otpCode: string;
  email: string;
}

export const getVetsuiteTokenWithOTP = () => ({
  mutationFn: ({ otpCode, email }: GetVetsuiteTokenProps) =>
    axiosHelper({
      endpoint: DigitalShowroomApi.VetsuiteAuth0PasswordlessToken,
      method: AxiosMethods.POST,
      body: {
        grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
        client_id: 'DchfbzBsTA4RZzzDocrjBhmqL4HynrqJ',
        client_secret:
          'doBNXg1EXJZBQvBqiSC_F7yT0Yx4T1xp7QtxdrhXb6RhDxoaJ3y2VSq6T2X2Hm-6',
        username: email,
        otp: otpCode,
        realm: 'email',
        scope: 'openid profile email'
      }
    })
});
