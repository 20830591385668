import { useEffect } from 'react';
import { Box, Divider, Theme } from '@mui/material';
import { SText } from '@setvi/shared/components';
import { VetsuiteOverview } from '@setvi/shared/services';
import moment from 'moment';
import { useDigitalShowroomContext } from '../../../../../../providers/app-provider/context';
import { useVetsuiteDigitalShowroomContext } from '../../../../providers/context';
import { useVetSuiteDigitalShowroomAnalytics } from '../../../../hooks';
import { ProfileDetail } from './detail';
import { Section } from '../../../../../../components/section';
import { getVetsuiteProgramName } from '../../../../utils';

const listContainerStyle = (theme: Theme) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '12px',
    marginTop: '12px'
  }
});

interface ProfileProps {
  overview: VetsuiteOverview;
}

export const Profile = ({ overview }: ProfileProps) => {
  const { loggedInUserEmail } = useDigitalShowroomContext();
  const { customerId } = useVetsuiteDigitalShowroomContext();
  const { captureAnalytics } = useVetSuiteDigitalShowroomAnalytics();
  const AdministrativeDetails = [
    // {
    //   iconBackgorundColor: '#0E44DE',
    //   iconSrc: '/icons/briefcase-icon.svg',
    //   label: 'Primary Practice',
    //   description: ''
    // },
    {
      iconBackgorundColor: '#89B46F',
      iconSrc: '/icons/person-icon.svg',
      label: '# of Veterinarians',
      description: overview?.fte
    }
    // {
    //   iconBackgorundColor: '#0E44DE',
    //   iconSrc: '/icons/mail-icon.svg',
    //   label: 'Practice Email',
    //   description: ''
    // }
  ];

  const VetsuiteContactDetails = [
    {
      iconBackgorundColor: '#DE69A5',
      iconSrc: '/icons/calendar-icon.svg',
      label: 'Start Date',
      description: moment(overview?.vetsuiteEnrollmentStartDate).format('l')
    },
    {
      iconBackgorundColor: '#7D96EB',
      iconSrc: '/icons/calendar-icon.svg',
      label: 'End Date',
      description: moment(overview?.vetsuiteEnrollmentEndDate).format('l')
    },
    {
      iconBackgorundColor: '#F6F6F6',
      iconSrc: '/icons/covetrus-logo-icon.svg',
      label: 'VetSuite Enrollment Level',
      description: getVetsuiteProgramName(overview)
    }
  ];

  const TechDetails = [
    {
      iconBackgorundColor: '#26BDBF',
      iconSrc: '/icons/vrx-pro-icon-white.svg',
      label: 'vRxPro',
      description: overview?.fullyConfigured
        ? 'Fully Configured'
        : 'Not Configured'
    },
    {
      iconBackgorundColor: '#FF9F29',
      iconSrc: '/icons/message-confirm-icon.svg',
      label: 'Communications',
      description: overview?.communicationsSystem ? 'Yes' : 'No'
    },
    {
      iconBackgorundColor: '#23A566',
      iconSrc: '/icons/building-icon.svg',
      label: 'Payments',
      description: overview?.paymentsSystem ? 'Yes' : 'No'
    },
    {
      iconBackgorundColor: '#23A566',
      iconSrc: '/icons/building-icon.svg',
      label: 'PIMS',
      description: overview?.pims
    }
  ];

  useEffect(() => {
    if (loggedInUserEmail)
      captureAnalytics({
        actionType: 'ProfileTab',
        covetrusKey: customerId,
        timestamp: new Date(),
        userEmail: loggedInUserEmail
      });
  }, [captureAnalytics, customerId, loggedInUserEmail]);

  return (
    <Section>
      <Box
        mb="46px"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            marginBottom: '20px'
          }
        })}>
        <SText
          title="Administrative Details"
          style={{ color: '#021660' }}
          size="lg"
          weight="bold"
        />
        <Box
          maxWidth="calc(33% - 16px)"
          alignItems="center"
          mt={3}
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              gap: '12px',
              marginTop: '12px',
              maxWidth: '100%!important'
            }
          })}>
          {AdministrativeDetails.map(el => (
            <ProfileDetail
              key={el.label}
              iconBackgorundColor={el.iconBackgorundColor}
              description={el.description}
              iconSrc={el.iconSrc}
              label={el.label}
            />
          ))}
        </Box>
      </Box>
      <Divider />
      <Box
        mt={4}
        mb="46px"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            margin: '20px 0px'
          }
        })}>
        <SText
          title="VetSuite Contract Details"
          style={{ color: '#021660' }}
          size="lg"
          weight="bold"
        />
        <Box
          display="flex"
          gap={5}
          alignItems="center"
          mt={3}
          sx={listContainerStyle}>
          {VetsuiteContactDetails.map(el => (
            <ProfileDetail
              key={el.label}
              iconBackgorundColor={el.iconBackgorundColor}
              description={el.description}
              iconSrc={el.iconSrc}
              label={el.label}
            />
          ))}
        </Box>
      </Box>
      <Divider />
      <Box
        mt={4}
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            marginTop: '20px'
          }
        })}>
        <SText
          title="Tech Details"
          style={{ color: '#021660' }}
          size="lg"
          weight="bold"
        />
        <Box
          display="flex"
          gap={5}
          alignItems="center"
          mt={3}
          sx={listContainerStyle}>
          {TechDetails.map(el => (
            <ProfileDetail
              key={el.label}
              iconBackgorundColor={el.iconBackgorundColor}
              description={el.description}
              iconSrc={el.iconSrc}
              label={el.label}
            />
          ))}
        </Box>
      </Box>
    </Section>
  );
};
