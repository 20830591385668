import { Box } from '@mui/material';

export const CurrentLevelPill = () => (
  <Box
    px={2}
    py={1}
    sx={theme => ({
      display: 'inline-block',
      textAlign: 'center',
      backgroundColor: '#27BDBE',
      color: theme.palette.common.white,
      borderRadius: '27px',
      width: 230,
      fontSize: '18px',
      border: '6px solid #fff'
    })}>
    Current Enrollment Level
  </Box>
);
