import { Box } from '@mui/material';

interface SectionProps {
  children: React.ReactNode;
}

export const Section = ({ children }: SectionProps) => (
  <Box
    p={4}
    component="section"
    sx={theme => ({
      backgroundColor: theme.palette.common.white,
      borderRadius: '24px'
    })}>
    {children}
  </Box>
);
