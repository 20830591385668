import { GetCovetrusUser } from '@setvi/shared/services';
import { createContext, useContext } from 'react';

export type DigitalShowroomContextType = {
  token: string;
  loggedInUserEmail: string;
  setLoggedInUserEmail: (email: string) => void;
  covetrusUser: GetCovetrusUser;
  setCovetrusUser: (user: GetCovetrusUser) => void;
};

export const DigitalShowroomContext = createContext<DigitalShowroomContextType>(
  {
    token: null,
    loggedInUserEmail: null,
    setLoggedInUserEmail: () => {},
    covetrusUser: null,
    setCovetrusUser: () => {}
  }
);

export const useDigitalShowroomContext = () => {
  const context = useContext(DigitalShowroomContext);

  if (context === undefined)
    throw new Error(
      `useVetsuiteDigitalShowroomContext must be used within a DigitalShowroomContext.Provider`
    );

  return context;
};
