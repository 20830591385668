import { VetsuiteOverview } from '@setvi/shared/services';
import { PIMS } from '../../../../providers/AccountData';
import { EnrollmentLevel } from '../../../../providers/types';

export const getSoftwareUtilization = (
  enrolmentLevel: EnrollmentLevel | string,
  pim: string
) => {
  const isCovetrusPIMS = [PIMS.Pulse, PIMS.Avimark, PIMS.Impromed].includes(
    pim as PIMS
  );

  if (enrolmentLevel === EnrollmentLevel.Select) {
    return {
      isCovetrusPIMS,
      name: isCovetrusPIMS ? pim : 'PiMS'
    };
  }

  if (enrolmentLevel === EnrollmentLevel.Premier) {
    return {
      isCovetrusPIMS: PIMS.Pulse === pim,
      name: isCovetrusPIMS ? pim : 'PiMS'
    };
  }

  return {
    isCovetrusPIMS: false,
    name: 'PiMS'
  };
};

export const isRebateAvailable = (
  overview: VetsuiteOverview,
  distributionProgress: number,
  compoundingProgress: number
): boolean => {
  let progressTarget = 70;

  if (overview?.enrolmentLevel === EnrollmentLevel.Select) progressTarget = 80;

  if (overview?.enrolmentLevel === EnrollmentLevel.Premier) progressTarget = 90;

  if (overview?.enrolmentLevel === EnrollmentLevel.Core) {
    return (
      distributionProgress >= progressTarget &&
      compoundingProgress >= progressTarget &&
      overview?.fullyConfigured
    );
  }

  if (overview?.enrolmentLevel === EnrollmentLevel.Select) {
    const hasRequiredPims = ['Avimark', 'Pulse', 'Impromed'].some(pim =>
      overview?.pims.includes(pim)
    );
    return (
      distributionProgress >= progressTarget &&
      compoundingProgress >= progressTarget &&
      overview?.fullyConfigured &&
      hasRequiredPims
    );
  }

  return (
    distributionProgress >= progressTarget &&
    compoundingProgress >= progressTarget &&
    overview?.fullyConfigured &&
    overview?.pims.includes('Pulse')
  );
};
