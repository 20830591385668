import { Box, styled } from '@mui/material';
import { SETVIImage, SText } from '@setvi/shared/components';
import DraftsIcon from '@mui/icons-material/Drafts';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

const Container = styled(Box)({
  height: 125,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-between'
});

interface HeaderProps {
  companyLogoSrc: string;
  representatioveFullName: string;
  representativeEmail: string;
  representativePhone?: string;
  representativeImageSrc?: string;
}

export const Header = ({
  companyLogoSrc,
  representatioveFullName,
  representativeEmail,
  representativePhone,
  representativeImageSrc
}: HeaderProps) => (
  <Container
    my={4}
    component="header"
    sx={theme => ({
      [theme.breakpoints.down('sm')]: {
        padding: 3,
        margin: 0,
        height: 100
      }
    })}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        flexShrink={0}
        mr={4}
        sx={theme => ({
          [theme.breakpoints.down('sm')]: {
            flexShrink: 1
          }
        })}>
        <SETVIImage
          style={{ width: '100%', maxWidth: 436 }}
          src={companyLogoSrc}
          alt="company-logo"
        />
      </Box>
      <Box
        flexShrink={0}
        display="flex"
        flexDirection="column"
        alignItems="end"
        sx={theme => ({ color: theme.palette.common.white })}>
        <Box
          sx={theme => ({
            color: theme.palette.grey[600],
            [theme.breakpoints.down('sm')]: {
              display: 'none'
            }
          })}
        />
        <Box mt={2} display="flex" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="end"
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none'
              }
            })}>
            <SText size="xl" title={representatioveFullName} />
            <Box display="flex" alignItems="center">
              {representativeEmail && (
                <Box display="flex" alignItems="center">
                  <DraftsIcon color="primary" />
                  <Box ml={2}>
                    <a
                      style={{ color: '#fff' }}
                      href={`mailto:${representativeEmail}`}>
                      {representativeEmail}
                    </a>
                  </Box>
                </Box>
              )}
              {representativePhone && (
                <Box ml={2} display="flex" alignItems="center">
                  <PhoneIcon color="primary" />
                  <Box ml={2}>
                    <a
                      style={{ color: '#fff' }}
                      href={`tel:${representativePhone}`}>
                      {representativePhone}
                    </a>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box ml={3}>
            <Box
              sx={theme => ({
                width: '48px',
                height: '48px',
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              })}>
              {representativeImageSrc ? (
                <SETVIImage
                  src={representativeImageSrc}
                  alt="representative-image"
                />
              ) : (
                <PersonOutlinedIcon fontSize="large" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Container>
);
