import { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup
} from '@mui/material';
import { SButton, SText } from '@setvi/shared/components';
import { VetsuiteOverview } from '@setvi/shared/services';
import { StringParam, useQueryParam } from 'use-query-params';
import CloseIcon from '@mui/icons-material/Close';

interface LocationSelectionFormProps {
  locations: VetsuiteOverview[];
  onConfirm: (location: VetsuiteOverview) => void;
  onClose?: () => void;
}

export const LocationSelectionForm = ({
  locations,
  onConfirm,
  onClose
}: LocationSelectionFormProps) => {
  const [customerId] = useQueryParam('customerId', StringParam);
  const [selectedLocation, setSelectedLocation] =
    useState<VetsuiteOverview['_id']>(customerId);

  return (
    <Box
      p={5}
      sx={theme => ({
        backgroundColor: theme.palette.common.white,
        borderRadius: '15px',
        color: '#05133A',
        width: 320,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      })}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <SText weight="bold" size="2xl" noLineClamp title="Select Location" />
          <SText
            noLineClamp
            title="Select the clinic you would like to access"
          />
        </Box>
        {onClose && (
          <Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box mt={4} maxHeight={330} overflow="auto">
        <FormControl>
          <RadioGroup onChange={e => setSelectedLocation(e.target.value)}>
            {locations.map(el => (
              <FormControlLabel
                // eslint-disable-next-line no-underscore-dangle
                checked={selectedLocation === el._id}
                // eslint-disable-next-line no-underscore-dangle
                key={el._id}
                sx={theme => ({
                  border: `1px solid ${theme.palette.divider}`,
                  margin: '1px',
                  marginBottom: '10px',
                  borderRadius: '15px',
                  padding: '16px',
                  '&>span': {
                    marginRight: '10px'
                  }
                })}
                // eslint-disable-next-line no-underscore-dangle
                value={el._id}
                control={<Radio />}
                label={el.accountName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mt="30px">
        <SButton
          disabled={!selectedLocation}
          onClick={() =>
            // eslint-disable-next-line no-underscore-dangle
            onConfirm(locations.find(el => el._id === selectedLocation))
          }
          fullWidth
          style={{
            backgroundColor: '#27BDBE',
            textTransform: 'capitalize',
            height: 57,
            borderRadius: 15
          }}>
          Continue
        </SButton>
      </Box>
    </Box>
  );
};
