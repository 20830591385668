import { Box } from '@mui/material';
import { SETVIImage, SText } from '@setvi/shared/components';
import { RoomLayout } from 'components';

import { useVetsuiteLogin } from './useVetsuiteLogin';
import { EmailForm } from './email-form';
import { OTPCodeForm } from './otp-code-form';
import { LocationSelectionForm } from './location-selection-form';

export const VetsuiteLogin = () => {
  const {
    email,
    showOTPCodeField,
    otpCode,
    emailCheckLoading,
    getTokenLoading,
    handleEmailCheck,
    handleOTPCode,
    showLocationSelection,
    covetrusUser,
    handleLocationSelection
  } = useVetsuiteLogin();

  return (
    <RoomLayout
      sx={{
        backgroundColor: '#021660',
        backgroundImage: 'url(/images/covetrus-login-background.svg)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'bottom'
      }}>
      <Box
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center">
        {showLocationSelection ? (
          <LocationSelectionForm
            locations={covetrusUser?.vetsuite}
            onConfirm={handleLocationSelection}
          />
        ) : (
          <Box
            p={5}
            sx={theme => ({
              backgroundColor: theme.palette.common.white,
              borderRadius: '15px',
              color: '#05133A',
              width: 320,
              height: 350,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginBottom: '20vh'
            })}>
            <Box display="flex" justifyContent="center">
              <SETVIImage src="/images/covetrus-logo.png" alt="covetrus-logo" />
            </Box>
            <Box mt={5} textAlign="center">
              <SText
                title="VetSuite"
                color="inherit"
                weight="bold"
                style={{ fontSize: '26px', width: '100%' }}
              />
              {showOTPCodeField ? (
                <Box textAlign="center">
                  <SText
                    title="One Time Passcode is sent to your email"
                    color="inherit"
                    style={{ width: '100%' }}
                  />
                  <OTPCodeForm
                    initialValues={{ OtpCode: otpCode }}
                    isLoading={getTokenLoading}
                    onSubmit={values => handleOTPCode(values.OtpCode)}
                  />
                </Box>
              ) : (
                <Box textAlign="center">
                  <SText
                    title="Enter email to continue"
                    color="inherit"
                    style={{ width: '100%' }}
                  />
                  <EmailForm
                    initialValues={{ Email: email }}
                    isLoading={emailCheckLoading}
                    onSubmit={values => handleEmailCheck(values.Email)}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </RoomLayout>
  );
};
