import { SText } from '@setvi/shared/components';
import { Transaction } from '@setvi/shared/services';
import { ColumnsType } from '@setvi/shared/types';
import moment from 'moment';

export const usePurchasingTable = () => {
  const columns: ColumnsType = [
    {
      id: 'date',
      label: 'Date',
      cellContent: (row: Transaction) => (
        <SText size="sm" title={moment(row.date).format('l')} />
      )
    },
    {
      id: 'type',
      label: 'Type',
      cellContent: (row: Transaction) => (
        <SText size="sm" title={row.purchaseType} />
      )
    },
    {
      id: 'purchase',
      label: 'Purchase',
      cellContent: (row: Transaction) => (
        <SText
          size="sm"
          title={row.sales.toLocaleString('en-US', {
            currency: 'USD',
            style: 'currency'
          })}
        />
      )
    }
  ];

  return { columns };
};
