import { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { VetsuiteOverview } from '@setvi/shared/services';
import { useDialog } from '@setvi/shared/providers';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getVetsuiteProgramName } from '../../utils';
import { ContentHeader } from './header';
import { ContentTabs } from './tabs';
import { TabContent } from './tabs/content';
import { LocationSelectionForm } from '../../../vetsuite-login/location-selection-form';
import { useDigitalShowroomContext } from '../../../../providers/app-provider/context';
import { ROUTES } from '../../../../router/routes';

export enum VetsuiteTabValue {
  OVERVIEW = 'overview',
  PERFORMANCE_ACTIVITY = 'performanceActivity',
  PROFILE = 'profile',
  PROGRAM_DETAILS = 'programDetails',
  CONTACT_US = 'contactUs'
}

const tabs = [
  { label: 'Overview', value: VetsuiteTabValue.OVERVIEW },
  {
    label: 'Performance Activity',
    value: VetsuiteTabValue.PERFORMANCE_ACTIVITY
  },
  {
    label: 'Profile',
    value: VetsuiteTabValue.PROFILE
  },
  {
    label: 'Program Details',
    value: VetsuiteTabValue.PROGRAM_DETAILS
  },
  {
    label: 'Contact Us',
    value: VetsuiteTabValue.CONTACT_US
  }
];

interface ContentProps {
  overview: VetsuiteOverview;
}

export const Content = ({ overview }: ContentProps) => {
  const t = useTheme();
  const matches = useMediaQuery(t.breakpoints.up('sm'));
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const { openDialog, closeDialog } = useDialog();
  const { covetrusUser, token } = useDigitalShowroomContext();
  const navigate = useNavigate();

  const handleChangeClinic = () =>
    openDialog(
      <LocationSelectionForm
        onClose={closeDialog}
        locations={covetrusUser?.vetsuite || []}
        onConfirm={location => {
          navigate(
            {
              pathname: ROUTES.HOME,
              search: createSearchParams({
                // eslint-disable-next-line no-underscore-dangle
                customerId: location._id,
                token
              }).toString()
            },
            { replace: true }
          );
          closeDialog();
        }}
      />
    );

  return (
    <Box
      component="main"
      display="flex"
      flexDirection="column"
      height="100%"
      minHeight={0}
      flexGrow={1}
      sx={theme => ({
        backgroundColor: '#FAFAFB',
        borderRadius: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0
        }
      })}>
      <Box bgcolor="#fff" borderRadius="12px 12px 0 0">
        <ContentHeader
          title={`${matches ? 'Covetrus VetSuite Dashboard - ' : ''}${overview?.accountName}`}
          onChangeClinic={handleChangeClinic}
          displayChangeClinic={covetrusUser?.vetsuite?.length > 1}
          subtitle={
            <>
              Enrollment Level: <b>{getVetsuiteProgramName(overview)}</b>
            </>
          }
        />
        <ContentTabs
          tabList={tabs}
          currentTab={currentTab}
          handleTabChange={setCurrentTab}
        />
      </Box>
      <Box overflow="auto" flex={1}>
        <TabContent
          overviewData={overview}
          activeTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </Box>
    </Box>
  );
};
