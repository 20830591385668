import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { VetSuiteDigitalShowroomModule } from 'modules';
import { ProtectedVetsuiteShowroom } from 'modules/vetsuite/components/protected-vetsuite-showroom';
import { VetsuiteLogin } from 'modules/vetsuite-login';
import App from 'App';
import { ROUTES } from './routes';

const routes = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: ROUTES.HOME,
        element: (
          <ProtectedVetsuiteShowroom>
            <VetSuiteDigitalShowroomModule />
          </ProtectedVetsuiteShowroom>
        )
      },
      {
        path: ROUTES.LOGIN,
        element: <VetsuiteLogin />
      }
    ]
  }
]);

export const AppRoutes = () => (
  <RouterProvider future={{ v7_startTransition: true }} router={routes} />
);
