import { Box } from '@mui/material';
import { SETVIImage, SText } from '@setvi/shared/components';

interface ProfileDetailProps {
  iconBackgorundColor: string;
  iconSrc: string;
  label: string;
  description: string | number;
}

export const ProfileDetail = ({
  iconBackgorundColor,
  iconSrc,
  label,
  description
}: ProfileDetailProps) => (
  <Box
    width="100%"
    boxSizing="border-box"
    p={3}
    flex={1}
    display="inline-flex"
    alignItems="center"
    sx={theme => ({
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '12px'
    })}>
    <Box
      width={50}
      height={50}
      mr={3}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
      sx={{ borderRadius: '50%', backgroundColor: iconBackgorundColor }}>
      <SETVIImage src={iconSrc} alt={label} />
    </Box>
    <Box>
      <Box sx={theme => ({ color: theme.palette.grey[900] })}>
        <SText noLineClamp title={label} size="sm" />
      </Box>
      <Box>
        <SText
          noLineClamp
          title={description?.toString()}
          weight="semibold"
          size="xl"
        />
      </Box>
    </Box>
  </Box>
);
