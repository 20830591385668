import * as Realm from 'realm-web';
import { useCallback, useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { StringParam, useQueryParam } from 'use-query-params';
import { Box } from '@mui/material';
import {
  MONGO_REALM_COLLECTIONS,
  VetsuiteOverview
} from '@setvi/shared/services';
import { AccountData } from './AccountData';
import { VetsuiteDigitalShowroomContext } from './context';
import { useDigitalShowroomContext } from '../../../providers/app-provider/context';

interface DigitalShowRoomProviderProps {
  children: React.ReactNode;
}

export const DigitalShowRoomProvider = ({
  children
}: DigitalShowRoomProviderProps) => {
  const [paramToken, setParamToken] = useQueryParam('token', StringParam);
  const digitalShowroomToken = localStorage.getItem('digitalShowroomToken');
  const [dsToken, setDsToken] = useState<string>(digitalShowroomToken || '');
  const [customerId] = useQueryParam('customerId', StringParam);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<VetsuiteOverview | null>(null);
  const { loggedInUserEmail } = useDigitalShowroomContext();

  const selectedCustomerData = AccountData.find(
    c => c.CovetrusID === customerId
  );

  const initialize = useCallback(
    async (jwt: string, _mongoRealmAppId: string) => {
      setLoading(true);
      const app = new Realm.App({
        id: process.env.CVET_MONGO_REALM_APP_ID
      });
      const credentials = Realm.Credentials.jwt(jwt);

      try {
        const user = await app.logIn(credentials);
        const initializeClient = app.currentUser.mongoClient(
          process.env.CVET_MONGO_CLIENT
        );
        const response: VetsuiteOverview = await initializeClient
          ?.db(process.env.CVET_REALM_DB_NAME)
          .collection(MONGO_REALM_COLLECTIONS.VETSUITE_OVERVIEW)
          .findOne({ _id: customerId });

        setData(response);
        setCurrentUser(user);
        setClient(initializeClient);
        setLoading(false);
        if (loggedInUserEmail)
          initializeClient
            .db(process.env.CVET_REALM_DB_NAME)
            .collection(MONGO_REALM_COLLECTIONS.DIGITAL_SHWOROOM_ANALYTICS)
            .insertOne({
              actionType: 'LoginSuccess',
              userEmail: loggedInUserEmail || '',
              timestamp: new Date(),
              covetrusKey: customerId
            });
      } catch (e) {
        setError(e);
        enqueueSnackbar(
          'Issue occured initializing module, try again later or contact Admin if issue persists.',
          {
            variant: 'error'
          }
        );
      }
    },
    [customerId, loggedInUserEmail]
  );

  useEffect(() => {
    if (paramToken || dsToken) {
      const mongoAppId = process.env.CVET_MONGO_REALM_APP_ID;
      initialize(paramToken || dsToken, mongoAppId);
      setDsToken(paramToken);
      localStorage.setItem('digitalShowroomToken', paramToken || dsToken);
      setParamToken(null, 'replaceIn');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramToken, dsToken]);

  const account = selectedCustomerData || AccountData[0];

  if (error)
    return (
      // Waiting for error design
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Box
          p={3}
          display="flex"
          alignItems="center"
          style={{ backgroundColor: '#fff' }}
          borderRadius={10}>
          <h3>Something went wrong.</h3>
        </Box>
      </Box>
    );

  if (loading)
    return (
      // Waiting for loader design
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Box
          p={3}
          display="flex"
          alignItems="center"
          flexDirection="column"
          style={{ backgroundColor: '#fff' }}
          borderRadius={10}>
          <video
            autoPlay
            loop
            muted
            style={{ width: 400, height: 400 }}
            src="/images/covertus-loader.mp4"
          />
        </Box>
      </Box>
    );

  return (
    <VetsuiteDigitalShowroomContext.Provider
      value={{
        account,
        allAccounts: AccountData,
        client,
        currentUser,
        customerId,
        realmDbName: process.env.CVET_REALM_DB_NAME,
        token: paramToken || dsToken || digitalShowroomToken,
        data
      }}>
      {children}
    </VetsuiteDigitalShowroomContext.Provider>
  );
};
