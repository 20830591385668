import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import {
  QueryCache,
  useMutation,
  QueryClient,
  useQueryClient,
  UseMutationResult,
  MutationCache
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

const extractErrorMessage = (error: AxiosError<any>) => {
  if (!error?.response) return 'Something went wrong, please try again later';

  if (error.response.data.error_description)
    return error.response.data.error_description;

  const data = error?.response?.data;

  if (data && typeof data === 'string') return data;

  if (typeof data.errors === 'object') {
    const errorKey = Object.keys(data.errors)[0];
    return data.errors[errorKey][0];
  }

  if (
    (data?.Error && typeof data?.Error === 'string') ||
    (data?.error && typeof data?.error === 'string')
  )
    return data?.Error || data?.error;

  if (
    (data?.Error?.Message && typeof data?.Error?.Message === 'string') ||
    (data?.error?.message && typeof data?.error?.message === 'string')
  )
    return data?.Error?.Message || data?.error?.message;

  return error?.message || 'Something went wrong, please try again later';
};

export function useReactQuery() {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false
          }
        },
        queryCache: new QueryCache({
          onError: error => {
            enqueueSnackbar(extractErrorMessage(error as AxiosError), {
              variant: 'error'
            });
          }
        }),
        mutationCache: new MutationCache({
          onError: error => {
            enqueueSnackbar(extractErrorMessage(error as AxiosError), {
              variant: 'error'
            });
          }
        })
      }),
    [enqueueSnackbar]
  );

  return {
    queryClient
  };
}

export function useSubscribedMutation(
  props: any,
  queries?: string[]
): UseMutationResult<any> {
  const queryClient = useQueryClient();

  return useMutation({
    ...props,
    onSuccess: () => {
      queries?.forEach((query: string) => {
        queryClient.invalidateQueries({
          queryKey: [query]
        });
      });
    }
  });
}
