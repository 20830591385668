import { Box, Divider } from '@mui/material';
import { SText } from '@setvi/shared/components';

interface ContentheaderProps {
  title: string;
  subtitle: JSX.Element;
  displayChangeClinic?: boolean;
  onChangeClinic: () => void;
}

export const ContentHeader = ({
  title,
  subtitle,
  displayChangeClinic,
  onChangeClinic
}: ContentheaderProps) => (
  <Box m={4} display="flex">
    <Box>
      <Box display="flex" alignItems="center">
        <SText size="2xl" title={title} maxLines={2} weight="bold" />
        {displayChangeClinic && (
          <Box ml={2}>
            <a href="#" onClick={onChangeClinic}>
              (Change clinic)
            </a>
          </Box>
        )}
      </Box>
      <SText noLineClamp>{subtitle}</SText>
    </Box>
    <Divider />
  </Box>
);
