import { STab, STabs } from '@setvi/shared/components';

interface ContentTabsProps {
  tabList?: STab[];
  currentTab: string;
  handleTabChange: (tab: string) => void;
}

export const ContentTabs = ({
  currentTab,
  tabList,
  handleTabChange
}: ContentTabsProps) => (
  <STabs
    topDivider
    padding="large"
    variant="scrollable"
    scrollButtons="on"
    tabsList={tabList}
    currentTab={currentTab}
    handleTabChange={handleTabChange}
  />
);
