import { Box } from '@mui/material';
import { SText } from '@setvi/shared/components';

interface ProgramValueProps {
  estimatedTotalValue: number;
  valueHighlighted: boolean;
}

export const ProgramValue = ({
  estimatedTotalValue,
  valueHighlighted
}: ProgramValueProps) => (
  <Box
    p={3}
    textAlign="center"
    sx={theme => ({
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '8px',
      border: `1px solid #27BDBE`,
      backgroundColor: valueHighlighted
        ? '#27BDBE0F'
        : theme.palette.common.white
    })}>
    <SText
      fontColor="#021660"
      title={`Estimated Total Value: ${estimatedTotalValue.toLocaleString(
        'en-US',
        {
          currency: 'USD',
          style: 'currency',
          maximumFractionDigits: 0
        }
      )}`}
      weight="semibold"
      size="lg"
    />
  </Box>
);
