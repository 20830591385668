import { ThemeProvider } from '@mui/material';
import { muiTheme } from '@setvi/shared/styles';
import { DigitalShowroom } from './components/digital-showroom';
import { DigitalShowRoomProvider } from './providers/provider';

const VetSuiteDigitalShowroomModule = () => (
  <ThemeProvider theme={muiTheme}>
    <DigitalShowRoomProvider>
      <DigitalShowroom />
    </DigitalShowRoomProvider>
  </ThemeProvider>
);

export default VetSuiteDigitalShowroomModule;
