import { useCallback } from 'react';
import { MONGO_REALM_COLLECTIONS } from '@setvi/shared/services';
import { useVetsuiteDigitalShowroomContext } from '../../providers/context';

enum AnalyticsEvent {
  LoginSuccess = 'LoginSuccess',
  LoginFailure = 'LoginFailure',
  OverviewTab = 'OverviewTab',
  PerformanceActivityTab = 'PerformanceActivityTab',
  ActivityTab = 'ActivityTab',
  ProfileTab = 'ProfileTab',
  ProgramDetailsTab = 'ProgramDetailsTab',
  ContactUsTab = 'ContactUsTab'
}

interface CaptureAnalyticsProps {
  actionType: keyof typeof AnalyticsEvent;
  userEmail: string;
  timestamp: Date;
  covetrusKey: string;
}

export const useVetSuiteDigitalShowroomAnalytics = () => {
  const { client, realmDbName } = useVetsuiteDigitalShowroomContext();

  const captureAnalytics = useCallback(
    ({
      actionType,
      userEmail,
      timestamp,
      covetrusKey
    }: CaptureAnalyticsProps) =>
      client
        .db(realmDbName)
        .collection(MONGO_REALM_COLLECTIONS.DIGITAL_SHWOROOM_ANALYTICS)
        .insertOne({
          actionType,
          userEmail: userEmail || '',
          timestamp,
          covetrusKey
        }),
    [client, realmDbName]
  );

  return { captureAnalytics };
};
