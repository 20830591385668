import { useCallback } from 'react';
import { Box } from '@mui/material';
import { VetsuiteOverview } from '@setvi/shared/services';
import { Overview } from '../overview';
import { PerfomranceActivity } from '../performance-activity';
import { Profile } from '../profile';
import { ProgramDetails } from '../program-details';
import { ContactUs } from '../contact-us';
import { VetsuiteTabValue } from '../..';

interface TabContentProps {
  activeTab: string;
  overviewData: VetsuiteOverview;
  setCurrentTab: (tab: string) => void;
}

export const TabContent = ({
  activeTab,
  overviewData,
  setCurrentTab
}: TabContentProps) => {
  const displayTab = useCallback(
    (tab: string) => {
      switch (tab) {
        case VetsuiteTabValue.OVERVIEW:
          return (
            <Overview
              overview={overviewData}
              onContactUsClick={() =>
                setCurrentTab(VetsuiteTabValue.CONTACT_US)
              }
              onProgramDetailsClick={() =>
                setCurrentTab(VetsuiteTabValue.PROGRAM_DETAILS)
              }
            />
          );
        case VetsuiteTabValue.PERFORMANCE_ACTIVITY:
          return <PerfomranceActivity overview={overviewData} />;
        case VetsuiteTabValue.PROFILE:
          return <Profile overview={overviewData} />;
        case VetsuiteTabValue.PROGRAM_DETAILS:
          return (
            <ProgramDetails
              overview={overviewData}
              setCurrentTab={setCurrentTab}
            />
          );
        case VetsuiteTabValue.CONTACT_US:
          return <ContactUs overview={overviewData} />;

        default:
          return null;
      }
    },
    [setCurrentTab, overviewData]
  );

  return (
    <Box
      p={5}
      sx={theme => ({
        [theme.breakpoints.down('sm')]: {
          padding: 0
        }
      })}>
      {displayTab(activeTab)}
    </Box>
  );
};
