import { createRoot } from 'react-dom/client';
import ReactFavicon from 'react-favicon';
import { AppRoutes } from 'router';

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);
root.render(
  <>
    <ReactFavicon url="/icons/favicon.ico" />
    <AppRoutes />
  </>
);
