import { Box, Typography } from '@mui/material';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const StyledLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 23,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#E2E3EC'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#243FA1'
  }
}));

interface CustomLinearProgresProps {
  currentProgress: number;
  sliderProgress: number;
  sliderProgressTitle: string;
}

export const CustomLinearProgres = ({
  currentProgress,
  sliderProgress,
  sliderProgressTitle
}: CustomLinearProgresProps) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    height={130}>
    <Box>
      {currentProgress < sliderProgress && (
        <Typography
          sx={{
            color: '#243FA1',
            size: '12px',
            fontWeight: 'bold',
            zIndex: 100
          }}>
          {currentProgress}%
        </Typography>
      )}
    </Box>
    <Box width="100%" position="relative">
      <StyledLinearProgress
        variant="determinate"
        value={currentProgress > 100 ? 100 : currentProgress}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '-20px',
          left: `${sliderProgress}%`
        }}>
        <Box position="relative" zIndex={10}>
          <Box
            sx={{
              position: 'absolute',
              transform: 'translate(-50%, -100%)',
              color: '#ED3030',
              fontWeight: 500
            }}>
            {sliderProgressTitle}
          </Box>
          <Box
            sx={{
              height: '45px',
              transform: 'translate(-50%)',
              borderLeft: '2px dashed #ED3030'
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              transform: 'translate(-50%, 20%)',
              color: '#ED3030',
              fontWeight: 500
            }}>
            {sliderProgress}%
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);
