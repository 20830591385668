import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { SText } from '@setvi/shared/components';

export interface MileStoneStepProps {
  completed: boolean;
  description: string;
  useBulletPoints?: boolean;
}

export const MilestonStep = ({
  completed,
  description,
  useBulletPoints
}: MileStoneStepProps) => (
  <Box display="flex" alignItems="center">
    <Box mr={2} display="flex">
      {useBulletPoints && (
        <Box
          style={{
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            backgroundColor: '#000'
          }}
        />
      )}
      {!useBulletPoints && (
        <>
          {completed ? (
            <CheckCircleIcon htmlColor="#27BDBE" />
          ) : (
            <CancelIcon htmlColor="#FFA133" />
          )}
        </>
      )}
    </Box>
    <Box>
      <SText noLineClamp title={description} weight="medium" />
    </Box>
  </Box>
);
