import { Box } from '@mui/material';
import { SETVIImage, SText } from '@setvi/shared/components';

interface RequirementProps {
  iconBackgorundColor: string;
  iconSrc: string;
  label: string;
  description: string;
}

export const Requirement = ({
  iconBackgorundColor,
  iconSrc,
  label,
  description
}: RequirementProps) => (
  <Box
    p={3}
    width="100%"
    display="flex"
    alignItems="center"
    boxSizing="border-box"
    sx={theme => ({
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '12px'
    })}>
    <Box
      width={50}
      height={50}
      mr={3}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
      sx={{ borderRadius: '50%', backgroundColor: iconBackgorundColor }}>
      <SETVIImage src={iconSrc} alt={label} />
    </Box>
    <Box>
      <Box sx={theme => ({ color: theme.palette.grey[900] })} mb={1}>
        <SText noLineClamp title={label} size="sm" />
      </Box>
      <Box>
        <SText title={description} weight="semibold" size="xl" />
      </Box>
    </Box>
  </Box>
);
