import { Box } from '@mui/material';
import { SText } from '@setvi/shared/components';

interface PerformanceActivityLegendProps {
  title: string;
  color: string;
}

export const PerformanceActivityLegend = ({
  title,
  color
}: PerformanceActivityLegendProps) => (
  <Box display="flex" alignItems="center">
    <Box
      flexShrink={0}
      sx={{
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        backgroundColor: color
      }}
    />
    <Box ml={2}>
      <SText noLineClamp title={title} size="xs" fontColor="#000" />
    </Box>
  </Box>
);
