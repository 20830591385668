import { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { GetCovetrusUser } from '@setvi/shared/services';
import { DigitalShowroomContext } from './context';

interface AppProviderProps {
  children: React.ReactNode;
}

// GLOBAL DIGITAL SHOWROOM CONTEXT NOT USED IN WEB APPLICATION ONLY DIGITAL SHOWROOM PLATFORM
export const AppProvider = ({ children }: AppProviderProps) => {
  const [token] = useQueryParam('token', StringParam);
  const digitalShowroomToken = localStorage.getItem('digitalShowroomToken');
  const [dsToken, setDsToken] = useState<string>(digitalShowroomToken || '');
  const loggedInUserEmailLS = localStorage.getItem('loggedInUserEmail');
  const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>(
    loggedInUserEmailLS || ''
  );
  const covetrusUserLS = JSON.parse(localStorage.getItem('covetrusUser'));
  const [covetrusUser, setCovetrusUser] = useState<GetCovetrusUser>(
    covetrusUserLS || null
  );

  const handleLoggedInUserEmail = (email: string) => {
    localStorage.setItem('loggedInUserEmail', email);
    setLoggedInUserEmail(email);
  };

  const handleCovetrusUser = (user: GetCovetrusUser) => {
    localStorage.setItem('covetrusUser', JSON.stringify(user));
    setCovetrusUser(user);
  };

  useEffect(() => {
    if (token) {
      setDsToken(token);
      localStorage.setItem('digitalShowroomToken', token);
    }
  }, [token]);

  return (
    <DigitalShowroomContext.Provider
      value={{
        token: dsToken || token,
        loggedInUserEmail,
        setLoggedInUserEmail: handleLoggedInUserEmail,
        covetrusUser,
        setCovetrusUser: handleCovetrusUser
      }}>
      {children}
    </DigitalShowroomContext.Provider>
  );
};
