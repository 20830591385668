import { Box, Divider } from '@mui/material';
import { SETVIImage, SText } from '@setvi/shared/components';
import { CustomLinearProgres } from '../custom-line-chart';

export interface PerformanceRequirementProps {
  icon: string;
  title: string;
  description: string;
  currentProgress: number;
  sliderProgress: number;
  sliderProgressTitle: string;
}

export const PerformanceRequirement = ({
  icon,
  title,
  description,
  currentProgress,
  sliderProgress,
  sliderProgressTitle
}: PerformanceRequirementProps) => (
  <Box
    p={2}
    sx={theme => ({
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px'
    })}>
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" mb={2}>
        <Box
          width={42}
          height={42}
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={2}
          sx={theme => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '5px',
            backgroundColor: theme.palette.grey[50]
          })}>
          <SETVIImage src={icon} alt="icon" />
        </Box>
        <Box>
          <Box>
            <Box>
              <SText title={title} weight="semibold" />
            </Box>
          </Box>
          <Box>
            <Box sx={theme => ({ color: theme.palette.grey[600] })}>
              <SText size="sm" title={description} color="inherit" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    <Divider />
    <Box mt={2}>
      <CustomLinearProgres
        currentProgress={currentProgress}
        sliderProgress={sliderProgress}
        sliderProgressTitle={sliderProgressTitle}
      />
    </Box>
    <Box mt={2} display="flex" justifyContent="center">
      <SText
        size="sm"
        title={
          currentProgress < sliderProgress
            ? `Your Commitment Level:${currentProgress}%`
            : 'Commitment Met'
        }
      />
    </Box>
  </Box>
);
