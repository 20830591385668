import { Account } from './types';

enum EnrollmentLevel {
  Core = 'Core',
  Select = 'Select',
  Premier = 'Premier'
}

enum TypeOfCustomer {
  PSI = 'PSI',
  VMG = 'VMG',
  Independent = 'Independent'
}

enum PrimaryPractice {
  CompanionAnimals = 'Companion Animals'
}

export enum PIMS {
  Avimark = 'Avimark',
  Pulse = 'Pulse',
  Impromed = 'Impromed',
  Vetter = 'Vetter',
  Cornerstone = 'Cornerstone'
}

enum Communications {
  PSICore = 'PSI Core -  Comm',
  PSISelect = 'PSI Select -  Comm',
  PSIPremier = 'PSI Premier -  Comm',
  VMGCore = 'VMG Core -  Comm',
  VMGSelect = 'VMG Select -  Comm',
  VMGPremier = 'VMG Premier -  Comm',
  Core = 'Core -  Comm',
  Select = 'Select -  Comm',
  Premier = 'Premier -  Comm'
}

enum Payments {
  PSICore = 'PSI Core -  Pay',
  PSISelect = 'PSI Select -  Pay',
  PSIPremier = 'PSI Premier -  Pay',
  VMGCore = 'VMG Core -  Pay',
  VMGSelect = 'VMG Select -  Pay',
  VMGPremier = 'VMG Premier -  Pay',
  Core = 'Core -  Pay',
  Select = 'Select -  Pay',
  Premier = 'Premier -  Pay'
}

enum vRxPro {
  Pulse1 = 'Pulse 1',
  Pulse2 = 'Pulse 2'
}

export const AccountData: Account[] = [
  {
    CovetrusID: 'C0026752',
    AccountName: 'ALAMEDA PET HOSPITAL',
    EnrollmentLevel: EnrollmentLevel.Core,
    TypeOfCustomer: TypeOfCustomer.PSI,
    EnrollmentStartDate: '2024-03-13',
    EnrollmentEndDate: '2027-03-13',
    FTE: 2,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account1@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Avimark,
    Communications: Communications.PSICore,
    Payments: Payments.PSICore,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 63,
    DistributionPerformanceYTD: 68,
    CompoudingPerformanceQTD: 73,
    CompoudingPerformanceYTD: 78,
    DistributionCommitmentPercent: 70,
    CompoudingCommitmentPercent: 70,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 5000,
    PerDVMRebate: 4250,
    RebateAmount: 13500,
    AccountManagerName: 'PSI Core AM',
    AccountManagerEmail: 'psicore@covetrus.com',
    AccountManagerPhone: 'PSI-CORE-1234',
    Requirements: {
      DistributionCommitment: 70,
      CompoundingCommitment: 70,
      FullyConfiguredVxPro: true,
      Communications: false,
      Payments: false,
      PIMS: '',
      DistributionPreferredPartner: '',
      ZoetisDiagnosticCommitment: false
    },
    Benefits: {
      Type: 'PSI',
      StaticRebate: 5000,
      PerDVMRebate: 4250,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: false,
      ExclusiveOSHAEducation: false,
      OneOnOneTrainingAndOnboarding: false,
      HypercareTechnologySupport: false,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: false,
      WhiteGloveService: false,
      AfterHoursTechSupport: false,
      PracticePerformanceReporting: false,
      PracticeBenchmarkReporting: false,
      WaiveShippingFees: false
    }
  },
  {
    CovetrusID: 'C0158351',
    AccountName: 'SISTERS VET CLINIC',
    EnrollmentLevel: EnrollmentLevel.Select,
    TypeOfCustomer: TypeOfCustomer.PSI,
    EnrollmentStartDate: '2024-04-08',
    EnrollmentEndDate: '2027-04-08',
    FTE: 2,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account2@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Avimark,
    Communications: Communications.PSISelect,
    Payments: Payments.PSISelect,
    vRxPro: vRxPro.Pulse2,
    DistributionPerformanceQTD: 83,
    DistributionPerformanceYTD: 88,
    CompoudingPerformanceQTD: 73,
    CompoudingPerformanceYTD: 78,
    DistributionCommitmentPercent: 80,
    CompoudingCommitmentPercent: 80,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 15000,
    PerDVMRebate: 7500,
    RebateAmount: 30000,
    AccountManagerName: 'PSI Select AM',
    AccountManagerEmail: 'psiselect@covetrus.com',
    AccountManagerPhone: 'PSI-SELECT-1234',
    Requirements: {
      DistributionCommitment: 80,
      CompoundingCommitment: 80,
      FullyConfiguredVxPro: true,
      Communications: true,
      Payments: true,
      PIMS: 'Covetrus PIMS (Latest Version of Pulse / Avimark / Imported)',
      DistributionPreferredPartner: 'Dual-Choice Preferred Partner',
      ZoetisDiagnosticCommitment: false
    },
    Benefits: {
      Type: 'PSI',
      StaticRebate: 15000,
      PerDVMRebate: 7500,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: false,
      ExclusiveOSHAEducation: false,
      OneOnOneTrainingAndOnboarding: true,
      HypercareTechnologySupport: false,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: true,
      WhiteGloveService: false,
      AfterHoursTechSupport: false,
      PracticePerformanceReporting: false,
      PracticeBenchmarkReporting: false,
      WaiveShippingFees: false
    }
  },
  {
    CovetrusID: 'C0043591',
    AccountName: 'HEAL VETERINARY CLINIC',
    EnrollmentLevel: EnrollmentLevel.Premier,
    TypeOfCustomer: TypeOfCustomer.PSI,
    EnrollmentStartDate: '2024-03-20',
    EnrollmentEndDate: '2027-03-20',
    FTE: 4,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account3@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Pulse,
    Communications: Communications.PSIPremier,
    Payments: Payments.PSIPremier,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 83,
    DistributionPerformanceYTD: 88,
    CompoudingPerformanceQTD: 93,
    CompoudingPerformanceYTD: 98,
    DistributionCommitmentPercent: 90,
    CompoudingCommitmentPercent: 90,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 20000,
    PerDVMRebate: 10250,
    RebateAmount: 61000,
    AccountManagerName: 'PSI Premier AM',
    AccountManagerEmail: 'psipremier@covetrus.com',
    AccountManagerPhone: 'PSI-PREMIER-1234',
    Requirements: {
      DistributionCommitment: 90,
      CompoundingCommitment: 90,
      FullyConfiguredVxPro: true,
      Communications: true,
      Payments: true,
      PIMS: 'Covetrus Pulse',
      DistributionPreferredPartner: 'Single-Choice Preffered Partner',
      ZoetisDiagnosticCommitment: true
    },
    Benefits: {
      Type: 'PSI',
      StaticRebate: 20000,
      PerDVMRebate: 10250,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: false,
      ExclusiveOSHAEducation: false,
      OneOnOneTrainingAndOnboarding: true,
      HypercareTechnologySupport: false,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: true,
      WhiteGloveService: true,
      AfterHoursTechSupport: true,
      PracticePerformanceReporting: true,
      PracticeBenchmarkReporting: true,
      WaiveShippingFees: false
    }
  },
  {
    CovetrusID: 'C0269902',
    AccountName: 'BIRMINGHAM ANIMAL HOSPITAL',
    EnrollmentLevel: EnrollmentLevel.Core,
    TypeOfCustomer: TypeOfCustomer.VMG,
    EnrollmentStartDate: '2024-03-26',
    EnrollmentEndDate: '2027-03-26',
    FTE: 4,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account4@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Pulse,
    Communications: Communications.VMGCore,
    Payments: Payments.VMGCore,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 63,
    DistributionPerformanceYTD: 68,
    CompoudingPerformanceQTD: 73,
    CompoudingPerformanceYTD: 78,
    DistributionCommitmentPercent: 70,
    CompoudingCommitmentPercent: 70,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 5500,
    PerDVMRebate: 4500,
    RebateAmount: 23500,
    AccountManagerName: 'VMG Core AM',
    AccountManagerEmail: 'vmgcore@covetrus.com',
    AccountManagerPhone: 'VMG-CORE-1234',
    Requirements: {
      DistributionCommitment: 70,
      CompoundingCommitment: 70,
      FullyConfiguredVxPro: true,
      Communications: false,
      Payments: false,
      PIMS: '',
      DistributionPreferredPartner: '',
      ZoetisDiagnosticCommitment: false
    },
    Benefits: {
      Type: 'VMG',
      StaticRebate: 5500,
      PerDVMRebate: 4500,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: true,
      ExclusiveOSHAEducation: true,
      OneOnOneTrainingAndOnboarding: false,
      HypercareTechnologySupport: false,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: false,
      WhiteGloveService: false,
      AfterHoursTechSupport: false,
      PracticePerformanceReporting: false,
      PracticeBenchmarkReporting: false,
      WaiveShippingFees: false
    }
  },
  {
    CovetrusID: 'C0269168',
    AccountName: 'NVC - Nederland',
    EnrollmentLevel: EnrollmentLevel.Select,
    TypeOfCustomer: TypeOfCustomer.VMG,
    EnrollmentStartDate: '2024-02-26',
    EnrollmentEndDate: '2027-02-26',
    FTE: 4,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account5@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Pulse,
    Communications: Communications.VMGSelect,
    Payments: Payments.VMGSelect,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 83,
    DistributionPerformanceYTD: 88,
    CompoudingPerformanceQTD: 73,
    CompoudingPerformanceYTD: 78,
    DistributionCommitmentPercent: 80,
    CompoudingCommitmentPercent: 80,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 15500,
    PerDVMRebate: 8000,
    RebateAmount: 47500,
    AccountManagerName: 'VMG Select AM',
    AccountManagerEmail: 'vmgselect@covetrus.com',
    AccountManagerPhone: 'VMG-SELECT-1234',
    Requirements: {
      DistributionCommitment: 80,
      CompoundingCommitment: 80,
      FullyConfiguredVxPro: true,
      Communications: true,
      Payments: true,
      PIMS: 'Covetrus PIMS (Latest Version of Pulse / Avimark / Imported)',
      DistributionPreferredPartner: 'Dual-Choice Preferred Partner',
      ZoetisDiagnosticCommitment: false
    },
    Benefits: {
      Type: 'VMG',
      StaticRebate: 15500,
      PerDVMRebate: 8000,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: true,
      ExclusiveOSHAEducation: true,
      OneOnOneTrainingAndOnboarding: true,
      HypercareTechnologySupport: false,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: true,
      WhiteGloveService: false,
      AfterHoursTechSupport: false,
      PracticePerformanceReporting: false,
      PracticeBenchmarkReporting: false,
      WaiveShippingFees: false
    }
  },
  {
    CovetrusID: 'C0055661',
    AccountName: 'KENOSHA ANIMAL HOSPITAL',
    EnrollmentLevel: EnrollmentLevel.Premier,
    TypeOfCustomer: TypeOfCustomer.VMG,
    EnrollmentStartDate: '2024-02-16',
    EnrollmentEndDate: '2027-02-16',
    FTE: 4,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account6@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Impromed,
    Communications: Communications.VMGPremier,
    Payments: Payments.VMGPremier,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 83,
    DistributionPerformanceYTD: 88,
    CompoudingPerformanceQTD: 93,
    CompoudingPerformanceYTD: 98,
    DistributionCommitmentPercent: 90,
    CompoudingCommitmentPercent: 90,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 21000,
    PerDVMRebate: 10500,
    RebateAmount: 63000,
    AccountManagerName: 'VMG Premier AM',
    AccountManagerEmail: 'vmgpremier@covetrus.com',
    AccountManagerPhone: 'VMG-PREMIER-1234',
    Requirements: {
      DistributionCommitment: 90,
      CompoundingCommitment: 90,
      FullyConfiguredVxPro: true,
      Communications: true,
      Payments: true,
      PIMS: 'Covetrus Pulse',
      DistributionPreferredPartner: 'Single-Choice Preffered Partner',
      ZoetisDiagnosticCommitment: true
    },
    Benefits: {
      Type: 'VMG',
      StaticRebate: 21000,
      PerDVMRebate: 10500,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: true,
      ExclusiveOSHAEducation: true,
      OneOnOneTrainingAndOnboarding: true,
      HypercareTechnologySupport: false,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: true,
      WhiteGloveService: true,
      AfterHoursTechSupport: true,
      PracticePerformanceReporting: true,
      PracticeBenchmarkReporting: true,
      WaiveShippingFees: true
    }
  },
  {
    CovetrusID: 'C0268365',
    AccountName: 'Pacific Grove Animal Hospital',
    EnrollmentLevel: EnrollmentLevel.Core,
    TypeOfCustomer: TypeOfCustomer.Independent,
    EnrollmentStartDate: '2024-02-05',
    EnrollmentEndDate: '2027-02-05',
    FTE: 4,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account7@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Vetter,
    Communications: Communications.Core,
    Payments: Payments.Core,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 63,
    DistributionPerformanceYTD: 68,
    CompoudingPerformanceQTD: 73,
    CompoudingPerformanceYTD: 78,
    DistributionCommitmentPercent: 70,
    CompoudingCommitmentPercent: 70,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 5000,
    PerDVMRebate: 4000,
    RebateAmount: 21000,
    AccountManagerName: 'Core AM',
    AccountManagerEmail: 'core@covetrus.com',
    AccountManagerPhone: 'IND-CORE-1234',
    Requirements: {
      DistributionCommitment: 70,
      CompoundingCommitment: 70,
      FullyConfiguredVxPro: true,
      Communications: false,
      Payments: false,
      PIMS: '',
      DistributionPreferredPartner: '',
      ZoetisDiagnosticCommitment: false
    },
    Benefits: {
      Type: 'Independent',
      StaticRebate: 5000,
      PerDVMRebate: 4000,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: false,
      ExclusiveOSHAEducation: false,
      OneOnOneTrainingAndOnboarding: false,
      HypercareTechnologySupport: false,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: false,
      WhiteGloveService: false,
      AfterHoursTechSupport: false,
      PracticePerformanceReporting: false,
      PracticeBenchmarkReporting: false,
      WaiveShippingFees: false
    }
  },
  {
    CovetrusID: 'C0047008',
    AccountName: 'MARO ROBERT DVM',
    EnrollmentLevel: EnrollmentLevel.Select,
    TypeOfCustomer: TypeOfCustomer.Independent,
    EnrollmentStartDate: '2024-02-15',
    EnrollmentEndDate: '2027-02-15',
    FTE: 1,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account7@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Pulse,
    Communications: Communications.Select,
    Payments: Payments.Select,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 83,
    DistributionPerformanceYTD: 88,
    CompoudingPerformanceQTD: 73,
    CompoudingPerformanceYTD: 78,
    DistributionCommitmentPercent: 80,
    CompoudingCommitmentPercent: 80,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 15000,
    PerDVMRebate: 7000,
    RebateAmount: 22000,
    AccountManagerName: 'Select AM',
    AccountManagerEmail: 'select@covetrus.com',
    AccountManagerPhone: 'IND-SELE-1234',
    Requirements: {
      DistributionCommitment: 80,
      CompoundingCommitment: 80,
      FullyConfiguredVxPro: true,
      Communications: true,
      Payments: true,
      PIMS: 'Covetrus PIMS (Latest Version of Pulse / Avimark / Imported)',
      DistributionPreferredPartner: 'Dual-Choice Preferred Partner',
      ZoetisDiagnosticCommitment: false
    },
    Benefits: {
      Type: 'Independent',
      StaticRebate: 15000,
      PerDVMRebate: 7000,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: false,
      ExclusiveOSHAEducation: false,
      OneOnOneTrainingAndOnboarding: true,
      HypercareTechnologySupport: true,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: true,
      WhiteGloveService: false,
      AfterHoursTechSupport: false,
      PracticePerformanceReporting: false,
      PracticeBenchmarkReporting: false,
      WaiveShippingFees: false
    }
  },
  {
    CovetrusID: 'C0019626',
    AccountName: 'STANTON ANIMAL HOSPITAL',
    EnrollmentLevel: EnrollmentLevel.Premier,
    TypeOfCustomer: TypeOfCustomer.Independent,
    EnrollmentStartDate: '2024-02-21',
    EnrollmentEndDate: '2027-02-21',
    FTE: 5,
    PrimaryPractice: PrimaryPractice.CompanionAnimals,
    PracticeEmail: 'account9@covetrus.com',
    FullyConfigured: 'Yes',
    PIMS: PIMS.Cornerstone,
    Communications: Communications.Premier,
    Payments: Payments.Premier,
    vRxPro: vRxPro.Pulse1,
    DistributionPerformanceQTD: 83,
    DistributionPerformanceYTD: 88,
    CompoudingPerformanceQTD: 93,
    CompoudingPerformanceYTD: 98,
    DistributionCommitmentPercent: 90,
    CompoudingCommitmentPercent: 90,
    DistributionCommitment: 100000,
    CompoudingCommitment: 100000,
    StaticRebate: 20000,
    PerDVMRebate: 10000,
    RebateAmount: 70000,
    AccountManagerName: 'Premier AM',
    AccountManagerEmail: 'premier@covetrus.com',
    AccountManagerPhone: 'IND-PREM-1234',
    Requirements: {
      DistributionCommitment: 90,
      CompoundingCommitment: 90,
      FullyConfiguredVxPro: true,
      Communications: true,
      Payments: true,
      PIMS: 'Covetrus Pulse',
      DistributionPreferredPartner: 'Single-Choice Preffered Partner',
      ZoetisDiagnosticCommitment: true
    },
    Benefits: {
      Type: 'Independent',
      StaticRebate: 20000,
      PerDVMRebate: 10000,
      DedicatedVetCareSupportTeam: true,
      QuartelyBusinessReviews: true,
      PersonalizedECommerceMarketing: true,
      InventoryManagementEducation: false,
      ExclusiveOSHAEducation: false,
      OneOnOneTrainingAndOnboarding: true,
      HypercareTechnologySupport: true,
      PriorityAccessToInnovationAndAllocationAndBackorderedProducts: true,
      WhiteGloveService: true,
      AfterHoursTechSupport: false,
      PracticePerformanceReporting: true,
      PracticeBenchmarkReporting: true,
      WaiveShippingFees: false
    }
  }
];
