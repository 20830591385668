import { QueryClientProvider } from '@tanstack/react-query';
import { useReactQuery } from './useReactQuery';

interface ReactQueryProviderProps {
  children: JSX.Element;
}

const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  const { queryClient } = useReactQuery();

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryProvider;
