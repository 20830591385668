import { Box } from '@mui/material';
import { SButton } from '@setvi/shared/components';
import SFormikTextfield from '@setvi/shared/components/sforms/sformik/formik-text-field';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

interface EmailFormProps {
  initialValues: {
    Email: string;
  };
  isLoading: boolean;
  onSubmit: (values: { Email: string }) => void;
}

const validationSchema = Yup.object({
  Email: Yup.string()
    .trim()
    .email('Invalid email address')
    .required('Email is required')
});

export const EmailForm = ({
  initialValues,
  isLoading,
  onSubmit
}: EmailFormProps) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    validateOnChange={false}>
    <Form>
      <Box mt={5}>
        <SFormikTextfield
          startIcon={<EmailOutlinedIcon htmlColor="#696974" />}
          name="Email"
          placeholder="Email Address"
        />
      </Box>
      <Box mt="30px">
        <SButton
          loading={isLoading}
          fullWidth
          style={{
            backgroundColor: '#27BDBE',
            textTransform: 'capitalize',
            height: 57,
            borderRadius: 15
          }}
          type="submit">
          Continue
        </SButton>
      </Box>
    </Form>
  </Formik>
);
